<template>
  <div class="product-box">
    <div class="head-box animated zoomIn">
      <div class="content">
        <div class="title animated fadeInDown">医院制度管理系统</div>
        <div class="tip animated fadeInUp">规章制度是医院管理的基础，使医院运行平稳、高效，并防患于未然，多安制度管理系统提供规章制度批量导入、批量导出、更新、回顾、审批、发布和废除等功能，将制度管理流程加以固化，提高规章制度管理水平和执行效果。实现规章制度管理与归口部门管理的平滑对接，使医院制度成体系，强执行，让工作有条不紊</div>
      </div>
    </div>

    <div class="box">
      <img class="img animated bounceInUp" style="width: 100%;" src="@/assets/img/product/27.png" />
    </div>

    <div class="box" v-for="val in initData" :key="val.id" :id="val.id">
      <div class="title animated" :style="{ visibility: val.show ? 'visible':'hidden' }" :class="{ bounceInLeft: val.show }">{{val.title}}</div>
      <div v-if="val.desc" class="desc animated" :style="{ visibility: val.show ? 'visible':'hidden' }" :class="{ bounceInRight: val.show }">{{val.desc}}</div>
      <img class="img  animated" :style="{ visibility: val.show ? 'visible':'hidden', width: val.width }" :class="{ bounceInUp: val.show }" :src="val.img" />
    </div>

    <div class="box" id="animatedBox9">
      <div class="title animated" :style="{ visibility: show9 ? 'visible':'hidden', color: '#333' }" :class="{ bounceInLeft: show9 }">
        产品优势
      </div>
      <img class="img animated" :style="{ visibility: show9 ? 'visible':'hidden', width: '700px' }" :class="{ bounceInUp: show9 }" src="@/assets/img/product/36.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initData: [
        {
          id: 'animatedBox1',
          show: false,
          title: '制度汇编有参考',
          desc: '提供制度汇编知识库分享，系统内置海量各类别标杆医院的优秀思想，支持医务人员参考借鉴。',
          img: require('@/assets/img/product/28.png'),
          width: '80%',
        },
        {
          id: 'animatedBox2',
          show: false,
          title: '制度起草全流程闭环管理',
          desc: '实现医院制度新增、意见征集、提交审批、制度评论、修订、废止、重新启用全流程闭环管理。',
          img: require('@/assets/img/product/29.png'),
          width: '500px',
        },
        {
          id: 'animatedBox3',
          show: false,
          title: '起草前意见征集，生效后评论留言',
          desc: '',
          img: require('@/assets/img/product/30.png'),
          width: '500px',
        },
        {
          id: 'animatedBox4',
          show: false,
          title: '制度到期提醒，修订痕迹对比，历史版本留痕',
          desc: '',
          img: require('@/assets/img/product/31.png'),
          width: '500px',
        },
        {
          id: 'animatedBox5',
          show: false,
          title: '提供多种制度查阅条件，方便制度高效检索',
          desc: '系统提供多种制度检索查阅条件，快速定位到相关的制度文件，让你查阅学习制度更便利快捷。',
          img: require('@/assets/img/product/32.png'),
          width: '300px',
        },
        {
          id: 'animatedBox6',
          show: false,
          title: '支持制度学习排行和在线考试，制度执行易落地',
          desc: '统计制度学习情况，展现制度学习排行，进度不足可进行督促学习，让制度及时得到学习并在工作中遵守，针对于学习情况可以通过考试管理进行落地。',
          img: require('@/assets/img/product/33.png'),
          width: '80%',
        },
        {
          id: 'animatedBox7',
          show: false,
          title: '多终端一体化应用，随时随地制度浏览',
          desc: '支持电脑端+移动端，与企业微信或者钉钉端对接，方便医务人员利用碎片化事件对于医院制度进行浏览，制度学习更高效。',
          img: require('@/assets/img/product/34.png'),
          width: '80%',
        },
        {
          id: 'animatedBox8',
          show: false,
          title: '无纸化，全流程，让效能更高、让经济和社会效益更高',
          desc: '在线自定义流程审批，把控审批节点及进度，体现PDCA闭环管理提升制度等日常医疗行为建设。',
          img: require('@/assets/img/product/35.png'),
          width: '600px',
        },
      ],
      show9: false,
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.initData.forEach((v) => {
        let element = document.getElementById(v.id)
        // 获取元素的位置和尺寸
        let rect = element.getBoundingClientRect();
        // 计算元素和浏览器窗口底部之间的距离
        let distance = window.innerHeight - rect.bottom;
        if (distance > -300) {
          v.show = true
        }
      })

      let element9 = document.getElementById('animatedBox9')
      let rect9 = element9.getBoundingClientRect();
      let distance9 = window.innerHeight - rect9.bottom;
      if (distance9 > -300) {
        this.show9 = true
      }
    },
  }
}
</script>

<style lang="less" scoped>
.product-box {
  height: 100%;
  padding-top: 68px;
  .head-box {
    position: relative;
    width: 100%;
    height: 600px;
    background: url("~@/assets/img/product/1.png") no-repeat center/cover;
    margin-bottom: 100px;
    .content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding: 100px 12% 40px;
      font-family: "楷体", "楷体_GB2312";
    }
    .title {
      font-size: 100px;
      font-weight: 500;
      text-shadow: 0 0 1px currentColor, -1px -1px 1px #000, 0 -1px 1px #000,
        1px -1px 1px #000, 1px 0 1px #000, 1px 1px 1px #000, 0 1px 1px #000,
        -1px 1px 1px #000, -1px 0 1px #000;
    }
    .tip {
      margin-top: 32px;
      font-size: 32px;
    }
  }
  .box {
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 80px;
    text-align: center;
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #4aa1ec;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 24px;
      font-weight: 500;
      color: #333;
      margin-bottom: 40px;
    }
  }
}
</style>
